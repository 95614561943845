export default class FJenisJabatan {
    constructor(
      id,
      kode1,
      description,
      statusActive,
      fdivisionBean,

    ) {
      this.id = id;
      this.kode1 = kode1;
      this.description = description;
      this.statusActive = statusActive;
      this.fdivisionBean = fdivisionBean;
    }
  
  }
  