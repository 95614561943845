<template>
    <v-card class="mt-2 ml-2 mr-2">
        <FJenisJabatanTable class="pl-2 pr-2" ref="refJenisJabatanTable"/>
    </v-card>
  </template>
  
  <script>
  import FJenisJabatanTable from "@/components/admin_setup/jenis_jabatan/FJenisJabatanTable";
  
  export default {
    name: "JenisJabatan",
    components: {
      FJenisJabatanTable
    },
    data() {
      return {
        tabTitle: 'JenisJabatan'
      }
    },
    computed: {
    },
    methods: {
      tabJenisJabatanClick(){
        this.$refs.refJenisJabatanTable.fetchParent()
      },
  
    },
  
  
  }
  </script>
  
  <style scoped>
  </style>